<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="PN dono do contrato" label-for="consultant">
            <v-select
              v-model="consultant_id"
              :reduce="(consultant) => consultant.id"
              :options="consultants"
              label="name"
              dir="ltr"
              :loading="loading.consultant"
              @input="consultantChanged"
              :class="getSelectErrorClass(v$.consultant_id.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.consultant_id.$error">
                Você deve informar um PN válido
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Cliente" label-for="customer">
            <v-select
              v-model="customer_id"
              :reduce="(customer) => customer.id"
              :options="customers"
              label="name"
              dir="ltr"
              :loading="loading.customer"
              :disabled="!consultant_id"
              @input="customerChanged"
              :class="getSelectErrorClass(v$.customer_id.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.customer_id.$error">
                Você deve informar um cliente
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group
            label="Compromisso vinculado"
            label-for="customer_appointment"
          >
            <v-select
              v-model="customer_appointment_id"
              :reduce="(customer_appointment) => customer_appointment.id"
              :options="customerAppointments"
              label="name"
              dir="ltr"
              :disabled="!customer_id"
              :loading="loading.customer_appointment"
              :class="getSelectErrorClass(v$.customer_appointment_id.$error)"
              @input="appointmentChanged"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.customer_appointment_id.$error">
                Você deve informar um compromisso
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group label="PN especialista" label-for="closer_consultant">
            <v-select
              v-model="specialist_consultant_id"
              :reduce="(specialist_consultant) => specialist_consultant.id"
              :options="specialistConsultants"
              label="name"
              dir="ltr"
              :loading="loading.specialist"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group label="Empresa" label-for="partner_company">
            <v-select
              v-model="partner_company_id"
              :reduce="(partner_company) => partner_company.id"
              :options="partnerCompanies"
              label="name"
              dir="ltr"
              :loading="loading.partner_company"
              @input="partnerCompanyChanged"
              :class="getSelectErrorClass(v$.partner_company_id.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.partner_company_id.$error">
                Você deve informar uma empresa
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group label="Produto" label-for="product">
            <v-select
              v-model="product_id"
              :reduce="(product) => product.id"
              :options="productsFilter"
              label="name"
              dir="ltr"
              :loading="loading.product"
              :disabled="!partner_company_id"
              @input="productChanged"
              :class="getSelectErrorClass(v$.product_id.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.product_id.$error">
                Você deve informar um produto
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group
            label="Solução financeira"
            label-for="financial_solution"
          >
            <v-select
              v-model="financial_solution_id"
              :reduce="(financial_solution) => financial_solution.id"
              :options="financialSolutions"
              label="name"
              dir="ltr"
              :loading="loading.financial_solution"
              :disabled="!product_id"
              @input="financialSolutionChanged"
              :class="getSelectErrorClass(v$.financial_solution_id.$error)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <div class="invalid-feedback">
              <span v-if="v$.financial_solution_id.$error">
                Você deve informar um serviço financeiro
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mt-1">
          <b-form-group label="Número da proposta" label-for="proposal_number">
            <b-form-input id="proposal_number" v-model="proposal_number" />
          </b-form-group>
        </b-col>
        <template
          v-if="financialSolutionFields && financialSolutionFields.length > 0"
        >
          <b-col
            cols="12"
            :md="financialSolutionField.value_type === 'text' ? '12' : '6'"
            v-for="(financialSolutionField, index) in financialSolutionFields"
            :key="financialSolutionField.id"
            class="mt-1"
          >
            <contract-custom-field
              v-if="financial_solution_field_values[index]"
              v-model="financial_solution_field_values[index].value"
              :financial-solution-field="financialSolutionField"
              :financial-solution-field-index="index"
              :validation="v$.financial_solution_field_values"
              :validate="true"
            />
          </b-col>
        </template>
      </b-row>
      <b-row class="justify-content-around mt-1 mb-2">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar size="48" variant="light-warning">
              <feather-icon size="24" icon="DollarSignIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              {{ grossValue | toCurrency }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              Valor bruto do contrato
            </b-card-text>
          </b-media-body>
        </b-media>
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar size="48" variant="light-success">
              <feather-icon size="24" icon="DollarSignIcon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              {{ netValue | toCurrency }}
            </h4>
            <b-card-text class="font-small-3 mb-0">
              Valor líquido do contrato
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row class="justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="cancelAction"
              :disabled="saving"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mx-1"
              :disabled="saving"
              @click.prevent="save"
            >
              <b-spinner v-if="saving" small />
              <feather-icon v-else icon="SaveIcon" class="mr-50" />
              <span>
                {{ saving ? "Salvando..." : "Salvar" }}
              </span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import _ from "lodash";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BSpinner,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { getVueSelectErrorClass } from "@/helpers/validators";
import { moneyToFloat, percentageToFloat } from "@/helpers/converters";
import ContractCustomField from "./ContractCustomField";
import * as types from "@/modules/contract/store/types";
import * as usertypes from "@/modules/account/store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as accountTypes from "@/modules/account/store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import { isPnpOrHigher } from "@/constants/consultants"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BAvatar,
    vSelect,
    ContractCustomField,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), v$: useVuelidate(), skin };
  },
  data() {
    return {
      loading: {
        consultant: false,
        customer: false,
        partner_company: false,
        product: false,
        financial_solution: false,
        specialist: false,
        customer_appointment: false,
      },
      saving: false,
      consultant_id: undefined,
      customer_id: undefined,
      partner_company_id: undefined,
      product_id: undefined,
      financial_solution_id: undefined,
      proposal_number: undefined,
      specialist_consultant_id: undefined,
      financial_solution_field_values: [],
      customer_appointment_id: undefined,
    };
  },
  validations() {
    return {
      consultant_id: { required },
      customer_id: { required },
      partner_company_id: { required },
      product_id: { required },
      financial_solution_id: { required },
      proposal_number: {},
      specialist_consultant_id: {},
      customer_appointment_id: { required },
      financial_solution_field_values: {
        $each: helpers.forEach({
          financial_solution_field_id: {
            checkRequiredField: (financial_solution_field_id) => {
              const financialSolutionFieldValue = _.find(
                this.financial_solution_field_values,
                (field_value) =>
                  field_value.financial_solution_field_id ===
                  financial_solution_field_id
              );
              if (financialSolutionFieldValue) {
                const value = this.getFieldValue(financialSolutionFieldValue);
                return (
                  !financialSolutionFieldValue.required ||
                  financialSolutionFieldValue.value_type === "boolean" ||
                  (financialSolutionFieldValue.value_type === "money" &&
                    value > 0) ||
                  (financialSolutionFieldValue.value_type === "percentage" &&
                    value > 0) ||
                  (financialSolutionFieldValue.value_type === "string" &&
                    value) ||
                  (financialSolutionFieldValue.value_type === "text" &&
                    value) ||
                  (financialSolutionFieldValue.value_type === "date" &&
                    value) ||
                  (financialSolutionFieldValue.value_type === "json" &&
                    value) ||
                  (financialSolutionFieldValue.value_type === "list" && value)
                );
              }
              return true;
            },
            checkStringFieldLength: (financial_solution_field_id) => {
              const financialSolutionFieldValue = _.find(
                this.financial_solution_field_values,
                (field_value) =>
                  field_value.financial_solution_field_id ===
                  financial_solution_field_id
              );
              if (financialSolutionFieldValue) {
                const value = this.getFieldValue(financialSolutionFieldValue);
                return (
                  financialSolutionFieldValue.value_type !== "string" ||
                  !value ||
                  value?.length <= 255
                );
              }
              return true;
            },
          },
          value_type: {},
          required: {},
          value: {},
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      consultants: types.CONSULTANTS,
      customers: sharedTypes.CUSTOMERS,
      partnerCompanies: sharedTypes.PARTNER_COMPANIES,
      products: sharedTypes.PRODUCTS,
      financialSolutions: types.FINANCIAL_SOLUTIONS,
      financialSolutionFields: types.FINANCIAL_SOLUTION_FIELDS,
      financialSolutionCalculationRules:
        types.FINANCIAL_SOLUTION_CALCULATION_RULES,
      specialistConsultants: sharedTypes.SPECIALIST_CONSULTANTS,
      productTax: sharedTypes.BUSINESS_CONFIG_PRODUCT_TAX,
      isHunter: accountTypes.IS_HUNTER,
      isCloser: accountTypes.IS_CLOSER,
      customerAppointments: types.CUSTOMER_APPOINTMENTS,
      userData: usertypes.USER
    }),
    productsFilter: function () {
      const products = _.filter(
        this.products,
        (val) => val.can_be_manually_reported
      );
      return products;
    },
    grossValue: function () {
      return _.sumBy(this.financial_solution_field_values, (field_value) => {
        let sum = 0;
        if (
          this.financialSolutionCalculationRules[
            field_value.financial_solution_field_id
          ]
        ) {
          const field = _.forEach(
            this.financialSolutionCalculationRules[
              field_value.financial_solution_field_id
            ],
            (rule) => {
              sum += rule.multiplier * this.getFieldValue(field_value);
            }
          );
        }
        return sum;
      });
    },
    netValue: function () {
      const financialSolution = _.find(
        this.financialSolutions,
        (financialSolution) =>
          financialSolution.id === this.financial_solution_id
      );
      if (financialSolution?.net_value_apply_tax) {
        return this.grossValue * (1 - this.productTax);
      }
      return this.grossValue;
    },
    isBlocked: function() {
      return this.$route.params.blocked
    }
  },
  async mounted() {
    this.loading.consultant = true;
    this.getProductTax().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao obter o valor do imposto. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    await this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os parceiros de negócio. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultant = false;
      });
    this.loading.partner_company = true;
    this.getPartnerCompanies()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as empresas. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.partner_company = false;
      });
    this.loading.specialist = true;
    this.getSpecialistConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os parceiros de negócio especialistas. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.specialist = false;
      });
    if (this.$route.params.id) {
      this.loading.product = true;
      this.getProductById(this.$route.params.id)
        .then((response) => {
          const { name, description, network_distribution_id } = response.data;
          this.name = name;
          this.description = description;
          this.network_distribution_id = network_distribution_id;
          this.v$.$touch();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar o produto selecionado. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.product = false;
        });
    }
    if (this.$route.params.appointment) {
      this.loading.customer = true;
      this.loading.customer_appointment = true;
      this.getAppointmentById(this.$route.params.appointment)
        .then((response) => {
          const { id, consultant_id, customer_id } = response.data;
          this.consultant_id = consultant_id;
          this.getCustomers()
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao carregar os clientes. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.customer_id = customer_id;
              this.loading.customer = false;
              this.v$.customer_id.$touch();
            });
          this.getCustomerAppointments({ customer_id, consultant_id })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao carregar os parceiros de negócio. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.customer_appointment_id = id;
              this.loading.customer_appointment = false;
              this.v$.customer_appointment_id.$touch();
            });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar o compromisso vinculado. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.loading.customer = false;
          this.loading.customer_appointment = false;
        });
    }
    if (this.isBlocked) {
      if (!this.$swal.isVisible()) {
        this.showBlockMessage();
      } else {
        this.$swal.update({
          didClose: () => this.showBlockMessage(),
        });
      }
    }
    this.consultant_id = this.userData.appify_consultant_id || undefined
    this.consultantChanged(this.consultant_id);
  },
  methods: {
    ...mapActions({
      saveContract: types.SAVE_CONTRACT,
      getProductById: types.GET_CONTRACT,
      getConsultants: types.GET_CONSULTANTS,
      getCustomers: sharedTypes.GET_ALL_CUSTOMERS,
      getPartnerCompanies: sharedTypes.GET_PARTNER_COMPANIES,
      getProducts: sharedTypes.GET_PRODUCTS,
      getFinancialSolutions: types.GET_FINANCIAL_SOLUTIONS,
      getFinancialSolutionFields: types.GET_FINANCIAL_SOLUTION_FIELDS,
      getFinancialSolutionCalculationRules:
        types.GET_FINANCIAL_SOLUTION_CALCULATION_RULES,
      getSpecialistConsultants: sharedTypes.GET_SPECIALIST_CONSULTANTS,
      getProductTax: sharedTypes.GET_BUSINESS_CONFIG_PRODUCT_TAX,
      getCustomerAppointments: types.GET_CUSTOMER_APPOINTMENTS,
      getAppointmentById: sharedTypes.GET_APPOINTMENT,
    }),
    consultantChanged(consultant_id) {
      this.changeSpecialistConsultant(consultant_id);
      this.customer_id = undefined;
      this.customer_appointment_id = undefined;
      if (consultant_id) {
        this.loading.customer = true;
        this.getCustomers({ consultant_id })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar os clientes. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading.customer = false;
          });
      }
    },
    changeSpecialistConsultant(consultant_id) {
      const consultant = _.find(this.consultants, {id: consultant_id})

      if (consultant && !isPnpOrHigher(consultant.id_consultant_role)) {
        this.specialist_consultant_id = null;
      } else {
        this.specialist_consultant_id = consultant.id;
      }
    },
    customerChanged(customer_id) {
      this.customer_appointment_id = undefined;
      if (customer_id) {
        this.loading.customer_appointment = true;
        this.getCustomerAppointments({ customer_id })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar os parceiros de negócio. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading.customer_appointment = false;
          });
      }
    },
    partnerCompanyChanged(partner_company_id) {
      this.product_id = undefined;
      this.financial_solution_id = undefined;
      if (partner_company_id) {
        this.getProducts({ partner_company_id }).catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os produtos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      }
    },
    productChanged(product_id) {
      this.financial_solution_id = undefined;
      if (product_id) {
        this.getFinancialSolutions({
          partner_company_id: this.partner_company_id,
          product_id,
        }).catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os produtos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      }
    },
    financialSolutionChanged(financial_solution_id) {
      this.financial_solution_field_values = [];
      if (financial_solution_id) {
        Promise.all([
          this.getFinancialSolutionFields({ financial_solution_id }).then(
            (response) => {
              this.financial_solution_field_values = _.map(
                response.data,
                (field) => ({
                  financial_solution_field_id: field.id,
                  value_type: field.value_type,
                  required: field.required,
                  value: field.value_type === "boolean" ? false : "",
                })
              );
            }
          ),
          this.getFinancialSolutionCalculationRules({ financial_solution_id }),
        ]).catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os campos de uma solução financeira. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      }
    },
    appointmentChanged(appointment_id) {
      const appointment = _.find(
        this.customerAppointments,
        (appointment) => appointment.id === appointment_id
      );
      this.specialist_consultant_id = appointment?.consultant_specialist_id;
    },
    async save() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const {
        consultant_id,
        customer_id,
        partner_company_id,
        product_id,
        financial_solution_id,
        financial_solution_field_values,
        proposal_number,
        specialist_consultant_id,
        customer_appointment_id,
      } = this;
      let payload = {
        consultant_id,
        customer_id,
        partner_company_id,
        product_id,
        financial_solution_id,
        customer_appointment_id,
        financial_solution_field_values: _.map(
          financial_solution_field_values,
          (field_value) => {
            return {
              financial_solution_field_id:
                field_value.financial_solution_field_id,
              value: this.getFieldValue(field_value),
            };
          }
        ),
        proposal_number,
        specialist_consultant_id,
      };
      this.saveContract(payload)
        .then((response) => {
          this.$swal({
            backdrop: true,
            title: "Contrato salvo com sucesso!",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Salvar apenas esse contrato",
            cancelButtonText: "Continuar e adicionar outro contrato",
            allowOutsideClick: false,
            allowEscapeKey: false,
            background: `${this.skin === "dark" ? "#283046" : ""}`,
            customClass: {
              confirmButton: "btn btn-secondary mb-1",
              cancelButton: "btn btn-primary",
            },
          }).then((result) => {
            if(result.value){
              this.$router.push({ name: "contracts-list" });
            }else{
              this.addNewContract()
            }
          });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar o contrato. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getFieldValue(field) {
      if (field.value_type === "money") {
        return moneyToFloat(field.value);
      } else if (field.value_type === "percentage") {
        return percentageToFloat(field.value);
      } else {
        return field.value;
      }
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    showBlockMessage() {
      this.$swal({
        title: "Acesso bloqueado",
        text: `Seu acesso ficará bloqueado enquanto não finalizar o report dos contratos. Para reabilitar o sistema, finalize o report.`,
        icon: "warning",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        customClass: {
          confirmButton: "btn btn-primary",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      });
    },
    addNewContract(){
      this.partner_company_id = undefined
      this.product_id = undefined
      this.financial_solution_id = undefined
      this.proposal_number = undefined
      this.financial_solution_field_values = []
      this.v$.$reset()
    },
    cancelAction(){
      if(!this.isBlocked){
        this.$router.push({ name: "contracts-list" })
      }else{
        this.$swal({
          title: "Acesso bloqueado",
          text: `Caso tenha sinalizado acidentalmente que fechou negócio, realize o report do contrato com o valor de R$0,01 (um centavo) e posteriormente solicite que seja deletado para que o sistema seja desbloqueado.`,
          icon: "warning",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          showCancelButton: false,
          confirmButtonText: "Fechar",
          customClass: {
            confirmButton: "btn btn-primary",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
      });
      }
    
    }
  },
};
</script>
