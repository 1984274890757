<template>
  <b-row class="align-items-center h-100">
    <b-col cols="12">
      <money-input
        v-if="financialSolutionField.value_type === 'money'"
        :id="`field-${financialSolutionField.id}`"
        :label="financialSolutionField.field_name"
        :value="value"
        @input="handleInput"
        :thereIsError="thereIsError"
        errorMessage="Você deve fornecer um montante válido"
      />
      <percentage-input
        v-else-if="financialSolutionField.value_type === 'percentage'"
        :id="`field-${financialSolutionField.id}`"
        :label="financialSolutionField.field_name"
        :value="value"
        @input="handleInput"
        :thereIsError="thereIsError"
        errorMessage="Você deve fornecer uma porcentagem válida"
      />
      <b-form-checkbox
        v-else-if="financialSolutionField.value_type === 'boolean'"
        switch
        :checked="value"
        @input="handleInput"
      >
        <span>{{ financialSolutionField.field_name }}</span>
      </b-form-checkbox>
      <b-form-group
        v-else-if="financialSolutionField.value_type === 'date'"
        :label="financialSolutionField.field_name"
        :label-for="financialSolutionField.field_name"
      >
        <b-form-input
          :id="financialSolutionField.field_name"
          type="date"
          :value="value"
          @input="handleInput"
          :class="{ 'is-invalid': thereIsError }"
        />
      </b-form-group>
      <b-form-group
        v-else-if="financialSolutionField.value_type === 'json'"
        :label="financialSolutionField.field_name"
        :label-for="financialSolutionField.field_name"
      >
        <b-form-input
          :id="financialSolutionField.field_name"
          :value="value"
          @input="handleInput"
          :class="{ 'is-invalid': thereIsError }"
        />
      </b-form-group>
      <b-form-group
        v-else-if="financialSolutionField.value_type === 'text'"
        :label="financialSolutionField.field_name"
        :label-for="financialSolutionField.field_name"
      >
         <b-form-textarea
          :id="financialSolutionField.field_name"
          :value="value"
          @input="handleInput"
          :class="{ 'is-invalid': thereIsError }"
        ></b-form-textarea>
        <div class="invalid-feedback">
          <span v-if="thereIsError">
            O campo {{ financialSolutionField.field_name }} é de preenchimento obrigatório
          </span>
        </div>
      </b-form-group>
      <b-form-group
        v-else-if="financialSolutionField.value_type === 'list'"
        :label="financialSolutionField.field_name"
        :label-for="financialSolutionField.field_name"
      >
        <v-select
          :options="financialSolutionField.options_list"
          dir="ltr"
          :value="value"
          @input="handleInput"
          :class="getSelectErrorClass(thereIsError)"
        >
          <template #no-options="{ search, searching, loading }">
            Sem resultados
          </template>
        </v-select>
        <div class="invalid-feedback">
          <span v-if="thereIsError">
            O campo {{ financialSolutionField.field_name }} é de preenchimento obrigatório
          </span>
        </div>
      </b-form-group>
      <b-form-group
        v-else
        :label="financialSolutionField.field_name"
        :label-for="financialSolutionField.field_name"
      >
        <b-form-input
          :value="value"
          @input="handleInput"
          :id="financialSolutionField.field_name"
          :class="{ 'is-invalid': thereIsStringRequiredError || thereIsStringSizeError }"
        />
        <div class="invalid-feedback">
          <span v-if="thereIsStringRequiredError">
            O campo {{ financialSolutionField.field_name }} é de preenchimento obrigatório
          </span>
          <span v-else-if="thereIsStringSizeError">
            O campo {{ financialSolutionField.field_name }} deve conter até 255 caracteres
          </span>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormCheckbox,
  BFormTextarea, BRow, BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getVueSelectErrorClass } from '@/helpers/validators'
import MoneyInput from '@/modules/shared/components/MoneyInput'
import PercentageInput from '@/modules/shared/components/PercentageInput'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    MoneyInput,
    PercentageInput,
    vSelect
  },
  directives: {
    Ripple
  },
  props: {
    value: [ String, Boolean ],
    financialSolutionField: Object,
    financialSolutionFieldIndex: Number,
    validation: Object,
    validate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    thereIsError() {
      return this.validate && this.validation.$dirty &&
        this.validation.$each.$response.$errors[this.financialSolutionFieldIndex].financial_solution_field_id.length > 0
    },
    thereIsStringSizeError() {
      return this.checkErrorByType('checkStringFieldLength')
    },
    thereIsStringRequiredError() {
      return this.checkErrorByType('checkRequiredField')
    }
  },
  methods: {
    handleInput (content) {
      this.$emit('input', content)
    },
    checkErrorByType(errorType) {
      let thereIsError = false
      if (this.validate && this.validation.$dirty) {
        const errors = this.validation.$each.$response.$errors[this.financialSolutionFieldIndex].financial_solution_field_id
        errors.forEach(error => {
          if (error.$validator === errorType) {
            thereIsError = true
          }
        })
      }
      return thereIsError
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError)
    }
  }
}
</script>
